export default function CarForm(el) {
  el.addEventListener("submit", submitForm);
  const target = document.querySelector("#form-items");

  el.addEventListener("change", (e) => submitForm());

  function submitForm(e) {
    e?.preventDefault();

    const formData = new FormData();

    el.querySelectorAll("select").forEach((select) =>
      formData.append(select.name, select.value)
    );
    const queryString = new URLSearchParams(formData).toString();
    const url = `/car-api?${queryString}`;

    target.style.opacity = "0.5";

    fetch(url, {
      method: "GET",
    })
      .then((response) => response.text())
      .then((html) => {
        console.log(html);
        target.innerHTML = html;
        target.style.opacity = "1";
      })
      .catch((error) => {
        // Handle any errors
      });
  }
}
