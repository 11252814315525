import PhotoSwipeLightbox from "photoswipe/lightbox";
import "photoswipe/style.css";
import PhotoSwipe from "photoswipe";

export default function Gallery(el) {
  const lightbox = new PhotoSwipeLightbox({
    gallery: "#my-gallery",
    children: "a",
    pswpModule: PhotoSwipe,
  });
  lightbox.init();
}
