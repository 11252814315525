export default function Menu(els) {
  const menu = document.querySelector(".mobile-nav");
  const menuInner = document.querySelector(".nav-inner");
  els.forEach((el) =>
    el.addEventListener("click", () => {
      document.body.classList.toggle("overflow-hidden");
      menu.classList.toggle("hidden");
      menuInner.classList.toggle("nav-fade-down");
    })
  );
}
