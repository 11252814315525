export default function More(els) {
  const more = document.querySelector(".more-text");
  const less = document.querySelector(".less-text");
  const btn = document.querySelector(".more-btn");
  els.forEach((el) =>
    el.addEventListener("click", () => {
      more.classList.remove("hidden");
      less.classList.add("hidden");
      btn.classList.add("hidden");
    })
  );
}
