import "core-js/stable";
import "regenerator-runtime/runtime";
import Menu from "./js/Menu";
import Gallery from "./js/Gallery";
import More from "./js/More";
import Overview from "./js/Overview";
import Form from "./js/Form";
import Headroom from "headroom.js";
import "./app.scss";
import Slider from "./js/Slider";

import lazySizes from "lazysizes";
import "lazysizes/plugins/unveilhooks/ls.unveilhooks";
import CarForm from "./js/CarForm";

runActions();

function action(attr, func) {
  if (attr.indexOf(".") !== -1 || attr.indexOf("#") !== -1) {
    document.querySelectorAll(attr).forEach((el) => func(el));
  } else {
    document
      .querySelectorAll(`[data-action="${attr}"]`)
      .forEach((el) => func(el));
  }
}

function runActions() {
  action("CarForm", CarForm);
  action("Slider", Slider);
  action("Gallery", Gallery);
  action("Form", Form);
  Menu(document.querySelectorAll(".mob-nav-btn"));
  Overview(document.querySelectorAll(".more-overview-btn"));
  More(document.querySelectorAll(".more-btn"));
  const headroomElement = document.querySelector("#main-header");
  const headroom = new Headroom(headroomElement, {
    offset: 50,
  });
  headroom.init();
}

// Only used in dev
if (module.hot) {
  module.hot.accept();
}
