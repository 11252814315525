import Flickity from "flickity";

function Slider(el) {
  el.classList.remove("hidden");
  const options = el.dataset?.options ? JSON.parse(el.dataset.options) : {};
  const indexEl = el.parentElement.querySelector("[data-index]");

  const flk = new Flickity(el, options);

  function nextSlide() {
    flk.next();
  }

  flk.on("change", (index) => {
    if (indexEl) indexEl.innerText = index + 1;
  });

  function prevSlide() {
    flk.previous();
  }

  document
    .querySelectorAll(options?.nextButton)
    .forEach((el) => el.addEventListener("click", nextSlide));

  document
    .querySelectorAll(options?.prevButton)
    .forEach((el) => el.addEventListener("click", prevSlide));

  document.addEventListener("lazyloaded", (e) => {
    console.log("lazyloaded");
    flk.resize();
  });

  return () => {
    console.log("destroy slider ");
    flk.destroy();
  };
}

export default Slider;
